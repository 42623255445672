/*footer*/
.footer {
    background:  var(--rms-main-color);
    color: #fff;
    padding: 15px 0;
}
.footer-left img {
    height: 35px;
}
.login-page {
    height: 100vh;
}
.login-body{
    height: calc(100vh - 65px);
    background: #FBFBFB;
}
.main-img-login{
    width: 100%;
    height: calc(100vh - 135px);
}

.btn-edotco{
    color: #fff;
    background-color: #b0d136;
    border-color: #b0d136;
}

.login-area {
    background: var(--rms-main-color);
    padding: 20px;
    border-radius: 10px;
}
.login-input-icon {
    top: 8px;
    left: 10px;
    margin-right: 10px;
    color: #DAD8D8;
}
.custom-password,
.custom-email{
    padding-left: 35px;
}

/*new css*/
.login-header-img-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}
.imack-logo-area{
    padding-top: 20px;
}
.imack-logo {
    height: 80px;
    margin: 0 auto;
    display: flex;
}


/*// Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .login-body {
        height: 100%;
    }
    .login-page-7-hide{
        display: none;
    }
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .login-body {
        height: 100%;
    }
    .login-page-7-hide{
        display: none;
    }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

.form-group {
    margin-bottom: 0.5rem;
}

.bg-light {
    background-color: #fdfdfd !important;
}
